var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs3: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel" },
                  [
                    _vm.isLoading && !_vm.specification
                      ? _c("LoaderCard", {
                          attrs: {
                            flat: "",
                            minHeight: "300px",
                            type: "spinner--center"
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading && _vm.specification
                      ? [
                          _c(
                            "v-layout",
                            {
                              staticClass: "profileContainer",
                              attrs: { wrap: "" }
                            },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "profile__userName" },
                                  [
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.specification.specification_number
                                        )
                                      )
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "statusPill",
                                        class:
                                          "statusBackgroundColor--" +
                                          _vm.specification.statusColor
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.specification.statusName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm.specification.organization
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Bureau")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.specification.organization
                                                .name
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$store.state.isServiceOrganization
                                ? [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Geupload door")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "value disabled" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.specification
                                                  .uploaded_by_user.name
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ]),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Geupload op")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "value disabled" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dateFormatTime")(
                                                  _vm.specification.created_at
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm.specification.submitted_by_user
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Ingediend door")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.specification
                                                .submitted_by_user.name
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.specification.submitted_at
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Ingediend op")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormatTime")(
                                                _vm.specification.submitted_at
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.specification.status_updated_by &&
                              _vm.specification.status_updated_at
                                ? [
                                    _vm.specification.isRejected
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Afgekeurd door")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.specification
                                                          .status_updated_by
                                                          .name
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Afgekeurd op")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateFormatTime"
                                                        )(
                                                          _vm.specification
                                                            .status_updated_at
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.specification.isAccepted
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Goedgekeurd door")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.specification
                                                          .status_updated_by
                                                          .name
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Goedgekeurd op")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "dateFormatTime"
                                                        )(
                                                          _vm.specification
                                                            .status_updated_at
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("# Uurregels")
                                ]),
                                _c("div", { staticClass: "value disabled" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.specification.hours_count)
                                    )
                                  ])
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("Som uren")
                                ]),
                                _c("div", { staticClass: "value disabled" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("hours")(
                                          _vm.specification.hours_sum
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("Totaal kosten")
                                ]),
                                _c("div", { staticClass: "value disabled" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.specification.cost_total
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ]),
                              _vm.specification.status_update_comment
                                ? _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Opmerkingen")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "value disabled" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.specification
                                                .status_update_comment
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm.specification.isUploaded &&
                          !_vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "", "pa-2": "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "success",
                                            icon: "task",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.showUpdateSpecificationDialog(
                                                "pending"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Indienen ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "", "pa-2": "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "warning",
                                            icon: "dangerous",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.showUpdateSpecificationDialog(
                                                "deleted"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Verwijderen ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$store.state.Auth.is_rockefeller
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { "pa-2": "", shrink: "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "file_download",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onClickExport($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" Exporteren ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.specification.isPending &&
                          _vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "", "pa-2": "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "success",
                                            icon: "task",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.showUpdateSpecificationDialog(
                                                "accepted"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Goedkeuren ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "pa-2": "", shrink: "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "warning",
                                            icon: "dangerous",
                                            small: "true"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.showUpdateSpecificationDialog(
                                                "rejected"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Afkeuren ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c(
                "v-flex",
                { attrs: { xs9: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "slider-color": "secondary" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.tabs, function(tab) {
                        return [
                          tab.visible
                            ? _c(
                                "v-tab",
                                {
                                  key: tab.key,
                                  staticClass: "tab__filled",
                                  class: { active: _vm.activeTab === tab.key },
                                  attrs: { href: tab.href },
                                  on: {
                                    click: function($event) {
                                      tab.click ? tab.click() : null
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(tab.name) + " ")]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            lazy: true,
                            "reverse-transition": false,
                            transition: false,
                            value: "tab-time-rules"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementPanel elementPanel--spaced fill-height"
                            },
                            [
                              _vm.isLoading && !_vm.specification
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      minHeight: "300px",
                                      type: "spinner--center"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoading && _vm.specification
                                ? [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { x12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: { "align-baseline": "" }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs6: "" } },
                                                  [
                                                    _c("PeriodFilter", {
                                                      on: {
                                                        change: _vm.applyDates
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                !_vm.specification.isPending
                                                  ? _c(
                                                      "v-flex",
                                                      { attrs: { xs3: "" } },
                                                      [
                                                        _c("ReportFilter", {
                                                          attrs: {
                                                            cssClass:
                                                              "with-border",
                                                            filters: {
                                                              cost_specification:
                                                                _vm
                                                                  .specification
                                                                  .id
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.tableFilters
                                                                .reports,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.tableFilters,
                                                                "reports",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "tableFilters.reports"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs3: "" } },
                                                  [
                                                    _c("ReportExpertFilter", {
                                                      attrs: {
                                                        cssClass: "with-border",
                                                        dmz:
                                                          _vm.specification
                                                            .organization.id,
                                                        filters: {
                                                          cost_specification:
                                                            _vm.specification.id
                                                        },
                                                        placeholder:
                                                          "Medewerkers"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.tableFilters
                                                            .users,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.tableFilters,
                                                            "users",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "tableFilters.users"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { x12: "" } },
                                          [
                                            _c("DataTable", {
                                              ref: "specificationDatatable",
                                              attrs: {
                                                options:
                                                  _vm.tableOptionsSpecificationLines,
                                                visibility: _vm.visibility
                                              },
                                              on: {
                                                "data-update":
                                                  _vm.refreshDatatableSpecificationLines
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            lazy: true,
                            "reverse-transition": false,
                            transition: false,
                            value: "tab-questions"
                          }
                        },
                        [_c("SpecificationQuestions")],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            lazy: true,
                            "reverse-transition": false,
                            transition: false,
                            value: "tab-statistics"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementPanel elementPanel--spaced fill-height"
                            },
                            [
                              _vm.isLoading && !_vm.specification
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      minHeight: "300px",
                                      type: "spinner--center"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoading && _vm.specification
                                ? [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _vm.isLoadingHourStatistics
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("LoaderCard", {
                                                  attrs: {
                                                    flat: "",
                                                    minHeight: "300px",
                                                    type: "spinner--center"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.isLoadingHourStatistics
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _vm.statisticsHours
                                                  ? _c("PieChart", {
                                                      ref: "typesPieChart",
                                                      attrs: {
                                                        data:
                                                          _vm.statisticsHours,
                                                        haslegendLink: true,
                                                        height: 300,
                                                        size: 150,
                                                        type: "types"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("v-flex", {
                                          staticClass: "separator",
                                          attrs: { xs12: "" }
                                        }),
                                        _c(
                                          "v-flex",
                                          { attrs: { x12: "" } },
                                          [
                                            _c("DataTable", {
                                              ref:
                                                "specificationUsersDatatable",
                                              attrs: {
                                                options:
                                                  _vm.tableOptionsStatisticsUsers,
                                                visibility: _vm.visibility
                                              },
                                              on: {
                                                "data-update":
                                                  _vm.refreshDatatableStatisticsUsers
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDisplayingUpdateSpecificationDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "600px" },
              model: {
                value: _vm.isDisplayingUpdateSpecificationDialog,
                callback: function($$v) {
                  _vm.isDisplayingUpdateSpecificationDialog = $$v
                },
                expression: "isDisplayingUpdateSpecificationDialog"
              }
            },
            [
              _c("UpdateSpecificationDialog", {
                attrs: {
                  specification: _vm.specification,
                  status: _vm.updateStatus
                },
                on: { submitted: _vm.specificationUpdated },
                model: {
                  value: _vm.isDisplayingUpdateSpecificationDialog,
                  callback: function($$v) {
                    _vm.isDisplayingUpdateSpecificationDialog = $$v
                  },
                  expression: "isDisplayingUpdateSpecificationDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }