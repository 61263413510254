var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", [_vm._v(_vm._s(_vm.description))])
              ]),
              _vm.$store.state.isServiceOrganization
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Opmerking" },
                        model: {
                          value: _vm.comment,
                          callback: function($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "actions" },
        [
          _c(
            "v-layout",
            { attrs: { "justify-end": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mr-3 fadedText link",
                        on: { click: _vm.close }
                      },
                      [_vm._v("Annuleren")]
                    ),
                    _vm.status === "pending"
                      ? _c(
                          "MiButton",
                          {
                            attrs: { color: "success", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v(" Indienen ")]
                        )
                      : _vm._e(),
                    _vm.status === "deleted"
                      ? _c(
                          "MiButton",
                          {
                            attrs: { color: "warning", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submitDelete($event)
                              }
                            }
                          },
                          [_vm._v(" Verwijderen ")]
                        )
                      : _vm._e(),
                    _vm.status === "accepted"
                      ? _c(
                          "MiButton",
                          {
                            attrs: { color: "success", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v(" Goedkeuren ")]
                        )
                      : _vm._e(),
                    _vm.status === "rejected"
                      ? _c(
                          "MiButton",
                          {
                            attrs: { color: "warning", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v(" Afkeuren ")]
                        )
                      : _vm._e()
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }