import { Rpc } from '@/models/Rpc';
import { currency, formatDate, hours } from '@/support/String';
import { Component, Vue, Watch } from 'vue-property-decorator';
import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { CostSpecificationLine, Duration } from '@/models/CostSpecificationLine';
import { CostSpecification, Status } from '@/models/CostSpecification';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { SpecificationStatistics, User } from '@/models/User';
import UpdateSpecificationDialog from '@/views/Invoice/Specification/UpdateSpecificationDialog/UpdateSpecificationDialog.vue';
import PeriodFilter from '@/components/filters/period-filter/PeriodFilter.vue';
import ReportFilter from '@/components/filters/report-filter/ReportFilter.vue';
import PieChart from '@/components/pie-chart/PieChart.vue';
import { Options } from '@/components/mi-dialog/MiDialog';
import SpecificationQuestions from './SpecificationQuestions/SpecificationQuestions.vue';
import { Export } from '../../../models/Export';

@Component<Specification>({
  components: {
    UpdateSpecificationDialog,
    SpecificationQuestions,
    PeriodFilter,
    ReportFilter,
    PieChart,
  },
})
export default class Specification extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Specificatie';

  protected activeTab = 'tab-time-rules';

  protected specification: CostSpecification | null = null;

  protected tableFilters: SpecificationLinesTableFilters = {};

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
    search: false,
  };

  protected isLoading = false;

  protected isLoadingHourStatistics = true;

  protected isDisplayingUpdateSpecificationDialog = false;

  protected updateStatus: Status | null = null;

  protected statisticsHours: StatisticsHoursChart[] = [];
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  public async mounted() {
    await this.initialize();
  }
  // #endregion

  // #region Class methods

  protected async initialize() {
    this.isLoading = true;
    await this.fetchSpecification();
    this.emitBreadcrumb();
    this.isLoading = false;
  }

  protected onClickExport(): void {
    this.exportCsv();
  }

  protected refreshDatatableSpecificationLines() {
    if (this.$refs.specificationDatatable) {
      (this.$refs.specificationDatatable as DataTable).refresh();
    }
  }

  protected refreshDatatableStatisticsUsers() {
    if (this.$refs.specificationUsersDatatable) {
      (this.$refs.specificationUsersDatatable as DataTable).refresh();
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          {
            name: this.breadcrumbParentName,
            path: this.breadcrumbParentPath,
          },
          {
            name: this.specification?.specification_number || '',
          },
        ],
      });
  }

  protected showUpdateSpecificationDialog(status: Status) {
    this.isDisplayingUpdateSpecificationDialog = true;
    this.updateStatus = status;
  }

  protected specificationUpdated(specification: CostSpecification) {
    if (this.specification) {
      this.specification.status = specification.status;
      this.specification.status_updated_at = specification.status_updated_at;
      this.specification.status_updated_by = specification.status_updated_by;
      this.specification.status_update_comment = specification.status_update_comment;
    }

    this.emitBreadcrumb();
  }

  protected applyDates(from: string, to: string): void {
    this.$set(this.tableFilters, 'period_start', from);
    this.$set(this.tableFilters, 'period_end', to);
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async exportCsv(): Promise<void> {
    await this.handleDownloadPromise(new Export().downloadCostSpecificationExcel({
      cost_specification: this.$route.params.id,
      organization: this.specification?.organization?.id || '',
    }));
  }

  protected async handleDownloadPromise(downloadPromise: void | Promise<AxiosResponse>): Promise<void> {
    if (! downloadPromise) { return; }

    const response = await downloadPromise.catch((error: AxiosError) => {
      ErrorHandler.network(error);
    });

    if (response) {
      if (response.status === 201) {
        this.$store.dispatch('openDialog', this.dialogSuccessDownloadRequest);
      } else {
        ErrorHandler.alert();
      }
    }
  }

  protected get dialogSuccessDownloadRequest(): Options {
    return {
      title: 'Succesvol toegevoegd aan wachtrij',
      text: 'De download is succesvol toegevoegd aan de wachtrij om gegenereerd te worden. Indien die klaar is zal die te bereiken zijn op de Mijn Exports pagina.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ga naar Mijn Exports',
          action: () => {
            this.$router.push('/mijn-exports');
          },
        },
        cancel: {
          text: 'Sluiten',
          color: 'text-light',
        },
      },
    };
  }

  protected async fetchSpecification() {
    if (! this.$route.params.id) {
      return;
    }

    this.specification = await new CostSpecification()
      .include([
        'organization',
        'uploaded_by_user',
        'submitted_by_user',
        'submitted_by_organization',
        'status_updated_by',
      ])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchHourStatistics() {
    if (this.statisticsHours?.length) {
      return;
    }

    this.isLoadingHourStatistics = true;
    const hourStatistics: HourStatistics = await new Rpc()
      .execute('cost-specificiation:statistics', {
        cost_specification: this.specification?.id,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (hourStatistics) {
      this.statisticsHours = [
        {
          name: 'Aantal dossier uren',
          color: '#67A7C4',
          y: hourStatistics.hour_statistics.report,
        },
        {
          name: 'Aantal overige uren',
          color: '#a09b1b',
          y: hourStatistics.hour_statistics.others,
        },
      ];
    }

    this.isLoadingHourStatistics = false;
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */
  protected get tabs() {
    return [
      {
        name: 'Uurregels',
        key: 'tab-time-rules',
        href: '#tab-time-rules',
        visible: true,
        click: () => {

        },
      },
      {
        name: 'vragen',
        key: 'tab-questions',
        href: '#tab-questions',
        visible: true,
        click: () => {
          //
        },
      },
      {
        name: 'Statistieken',
        key: 'tab-statistics',
        href: '#tab-statistics',
        visible: this.specification?.isAccepted || false,
        click: () => {
          this.fetchHourStatistics();
        },
      },
    ];
  }

  protected get tableOptionsSpecificationLines(): TableOptions {
    return {
      model: new CostSpecificationLine().include(['report_lite']).filter(this.dataTableFilters).filter({
        specification: this.specification?.id,
        organization: this.specification?.organization?.id,
      }),
      sort: {
        key: 'duration',
        order: 'DESC',
      },
      headers: [
        {
          text: 'Rapport',
          value: 'report_name',
          width: '15%',
          class: (costSpecificationLine: CostSpecificationLine) => {
            if (! costSpecificationLine?.report_lite) {
              return '';
            }

            return 'action--span';
          },
          transform: (report_name: string, costSpecificationLine: CostSpecificationLine) => `${report_name}`,
          action: (costSpecificationLine: CostSpecificationLine) => {
            if (! costSpecificationLine?.report_lite) {
              return;
            }

            window.open(`/reports/${costSpecificationLine.report_lite.id}`, '_blank');
          },
        },
        {
          text: 'Datum',
          value: 'date',
          sortable: {
            key: 'date',
            order: 'ASC',
          },
          transform: (date: string, costSpecificationLine: CostSpecificationLine) => {
            if (! date) { return; }
            return formatDate(date, 'dd-LL-yyyy');
          },
        },
        {
          text: 'Medewerker',
          value: 'user',
          transform: (user: User, costSpecificationLine: CostSpecificationLine) => {
            if (! user) {
              return '';
            }

            return user.name;
          },
        },
        {
          text: 'Uursoort',
          value: 'type',
        },
        {
          text: 'Uren',
          value: 'duration',
          class: 'text-right',
          sortable: {
            key: 'duration',
            order: 'DESC',
          },
          transform: (duration: Duration, costSpecificationLine: CostSpecificationLine) => {
            if (! duration) {
              return '';
            }

            return hours(duration.hours);
          },
        },
        {
          text: 'Tarief',
          value: 'unitprice',
          class: 'text-right',
          transform: (unitprice: number, costSpecificationLine: CostSpecificationLine) => {
            if (! unitprice) {
              return '';
            }

            return hours(unitprice);
          },
        },
        {
          text: 'Subtotaal',
          value: 'subtotal',
          class: 'text-right',
          sortable: {
            key: 'subtotal',
            order: 'DESC',
          },
          transform: (subtotal: number, costSpecificationLine: CostSpecificationLine) => {
            if (! subtotal) {
              return '';
            }

            return currency(subtotal, 'EUR');
          },
          visible: () => this.$store.state.Auth.is_rockefeller,
        },
      ],
      actions: [],
      filters: [],
    };
  }

  protected get tableOptionsStatisticsUsers(): TableOptions {
    return {
      model: new User().include(['specification_statistics']).dmz(this.specification?.organization?.id || '').filter({
        cost_specification: this.specification?.id,
      }),
      headers: [
        {
          text: 'Medewerker',
          value: 'name',
        },
        {
          text: '# Uur wel dossier',
          value: 'specification_statistics',
          class: 'text-right',
          transform: (specification_statistics: SpecificationStatistics, costSpecificationLine: CostSpecificationLine) => {
            if (! specification_statistics?.hour_statistics) {
              return '';
            }

            return hours(specification_statistics.hour_statistics.report);
          },
        },
        {
          text: '# Uur niet dossier',
          value: 'specification_statistics',
          class: 'text-right',
          transform: (specification_statistics: SpecificationStatistics, costSpecificationLine: CostSpecificationLine) => {
            if (! specification_statistics?.hour_statistics) {
              return '';
            }

            return hours(specification_statistics.hour_statistics.others);
          },
        },
        {
          text: 'Totaal',
          value: 'specification_statistics',
          class: 'text-right',
          transform: (specification_statistics: SpecificationStatistics, costSpecificationLine: CostSpecificationLine) => {
            if (! specification_statistics?.hour_statistics) {
              return '';
            }

            return hours(specification_statistics.hour_statistics.total);
          },
        },
      ],
      actions: [],
      filters: [],
    };
  }

  protected get dataTableFilters() {
    return this.tableFilters;
  }

  protected get breadcrumbParentPath(): string {
    const pendingPath = this.$store.state.isServiceOrganization
      ? '/urenadministratie/ingediende-specificaties'
      : '/urenadministratie/specificaties-indienen';

    if (this.specification?.isPending) {
      return pendingPath;
    }

    if (this.specification?.isUploaded) {
      return '/urenadministratie/geuploade-specificaties';
    }

    if (this.specification?.isRejected) {
      return '/urenadministratie/afgekeurde-specificaties';
    }

    if (this.specification?.isAccepted) {
      return '/urenadministratie/goedgekeurde-specificaties';
    }

    return pendingPath;
  }

  protected get breadcrumbParentName(): string {
    const pendingName = this.$store.state.isServiceOrganization
      ? 'Ingediende specificaties'
      : 'Factuur specificaties indienen';

    if (this.specification?.isPending) {
      return pendingName;
    }

    if (this.specification?.isUploaded) {
      return 'Geuploade specificaties';
    }

    if (this.specification?.isRejected) {
      return 'Afgekeurde specificaties';
    }

    if (this.specification?.isAccepted) {
      return 'Goedgekeurde specificaties';
    }

    return pendingName;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
  // #endregion
}

interface SpecificationLinesTableFilters {
  period_start?: string;
  period_end?: string;
  reports?: string[];
  users?: string[];
}

interface StatisticsHoursChart {
  name: string;
  color: string;
  y: number;
}

interface HourStatistics {
  hour_statistics: {
    report: number;
    others: number;
  }
}
