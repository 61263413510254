import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep, debounce } from 'lodash';
import { Report } from '@/models/Report';

@Component<ReportFilter>({})
export default class ReportFilter extends Vue {
  @Prop()
  protected value!: any;

  @Prop()
  protected filters!: any;

  @Prop({ default: true })
  protected multiple!: boolean;

  protected defaultFilters: any = {};

  @Prop({ default: 'with-full-border' })
  protected cssClass!: string;

  protected reports: Report[] = [];

  protected selectedReports: Report[] = [];

  public searchDebounce: Function = this.handleReportSearch();

  protected reportSearchInput = '';

  protected handleChange() {
    this.$emit('input', this.selectedReports);
    this.$emit('handleChange');
  }

  @Watch('value')
  protected itemsChanged() {
    // clear items
    if (! this.value) {
      this.selectedReports = [];
    } else {
      this.selectedReports = cloneDeep(this.value);
    }
  }

  public async mounted() {
    this.defaultFilters = { ...this.filters, ...this.defaultFilters };
    await this.getReports();
  }

  protected async getReports(): Promise<void> {
    this.reports = await new Report()
      .refactor()
      .filter('search', this.reportSearchInput)
      .filter(this.defaultFilters)
      .limit(50)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected handleReportSearch() {
    return debounce(() => {
      this.getReports();
    }, 400);
  }
}
