import { CostSpecification, Status } from '@/models/CostSpecification';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<updateSpecificationDialog>({})
export default class updateSpecificationDialog extends Vue {
  @Prop()
  protected specification!: CostSpecification;

  @Prop()
  protected status!: Status;

  protected comment = '';

  protected mounted() {

  }

  protected close() {
    this.$emit('input', false);
  }

  protected async submit() {
    if (! this.specification) {
      return;
    }

    const specification = await this.specification
      .include(['status_updated_by'])
      .update({
        status: this.status,
        status_update_comment: this.comment,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.close();
    this.$emit('submitted', specification);
  }

  protected async submitDelete() {
    if (! this.specification) {
      return;
    }

    await this.specification
      .delete()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.close();
    this.$router.push('/urenadministratie/specificaties-uploaden');
  }

  protected get title() {
    if (this.status === 'pending') {
      return 'Specificatie indienen';
    }

    if (this.status === 'deleted') {
      return 'Specificatie verwijderen';
    }

    if (this.status === 'accepted') {
      return 'Specificatie goedkeuren';
    }

    return 'Specificatie afkeuren';
  }

  protected get description() {
    if (this.status === 'pending') {
      return 'Weet u zeker dat u deze specificatie wilt indienen?';
    }

    if (this.status === 'deleted') {
      return 'Weet u zeker dat u deze specificatie wilt verwijderen?';
    }

    if (this.status === 'accepted') {
      return 'Weet u zeker dat u deze specificatie wilt goedkeuren?';
    }

    return 'Weet u zeker dat u deze specificatie wilt afkeuren?';
  }
}
