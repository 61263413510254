var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-bg-white tw-flex tw-flex-wrap tw-p-24 tw-w-full" },
    [
      _c(
        "div",
        { staticClass: "tw-w-full tw-flex tw-justify-between tw-mb-32" },
        [
          _c("h2", { staticClass: "elementSubTitle tw-self-center" }, [
            _vm._v("Vragen")
          ]),
          _vm.$store.state.isServiceOrganization
            ? _c(
                "MiButton",
                {
                  staticClass: "mx-1",
                  attrs: {
                    disabled: _vm.isCreatingQuestion,
                    color: "primary",
                    icon: "keyboard_arrow_down"
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.showQuestionField = !_vm.showQuestionField
                    }
                  }
                },
                [_vm._v(" Stel een vraag ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.showQuestionField
        ? [
            _c("div", { staticClass: "tw-w-full tw-pt-8 tw-mb-32" }, [
              _c(
                "div",
                {
                  staticClass:
                    "tw-w-full tw-flex tw-border-gray-200 tw-border tw-border-solid tw-mb-32 tw-flex-wrap"
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "tw-p-16 tw-w-full" },
                    [
                      _c("v-textarea", {
                        staticClass: "tw-w-full",
                        attrs: { placeholder: "Schrijf hier uw bericht" },
                        model: {
                          value: _vm.questionPayload.question,
                          callback: function($$v) {
                            _vm.$set(_vm.questionPayload, "question", $$v)
                          },
                          expression: "questionPayload.question"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-w-full tw-justify-end tw-mb-16 tw-mx-16"
                    },
                    [
                      _c(
                        "MiButton",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            disabled: !_vm.questionPayload.question,
                            color: "primary",
                            compact: true,
                            icon: "send"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.submitNewQuestion()
                            }
                          }
                        },
                        [_vm._v(" Verzenden ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tw-w-full" },
        [
          _c("DataTable", {
            ref: "datatable",
            attrs: { options: _vm.tableOptions, visibility: _vm.visibility }
          })
        ],
        1
      ),
      _vm.isViewingQuestion
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "900px", persistent: "" },
              model: {
                value: _vm.isViewingQuestion,
                callback: function($$v) {
                  _vm.isViewingQuestion = $$v
                },
                expression: "isViewingQuestion"
              }
            },
            [
              _c("SpecificationQuestionDialog", {
                attrs: { question: _vm.selectedQuestion },
                on: {
                  questionUpdated: function($event) {
                    return _vm.datatable.refresh()
                  }
                },
                model: {
                  value: _vm.isViewingQuestion,
                  callback: function($$v) {
                    _vm.isViewingQuestion = $$v
                  },
                  expression: "isViewingQuestion"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tw-w-full tw-p-16 tw-bg-gray-200" }, [
      _c("span", { staticClass: "tw-font-bold tw-text-xl" }, [
        _vm._v("Stel een vraag")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }