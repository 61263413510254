var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("LoaderCard", {
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "h2",
                      {
                        staticClass: "tw-font-normal tw-text-gray-500 tw-mb-8"
                      },
                      [_vm._v("Vraag")]
                    ),
                    _c(
                      "div",
                      {
                        key: _vm.questionData.id,
                        staticClass: "tw-mb-32",
                        class: _vm.questionData.show_comment_field
                          ? "tw-border-secondary-300 tw-border tw-border-solid"
                          : ""
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-w-full tw-flex tw-mb-16 tw-border-gray-200 tw-border-2 tw-border-solid tw-flex-wrap"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-w-full tw-flex tw-justify-between tw-p-16 tw-bg-gray-100"
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "tw-font-bold tw-text-xl" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.questionData.asked_by
                                              ? _vm.questionData.asked_by.name
                                              : ""
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "tw-text-lg tw-ml-4" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.questionData.created_at)
                                      )
                                    ]
                                  )
                                ]),
                                _vm.canEditQuestion
                                  ? _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          icon: _vm.isEditingQuestion
                                            ? "close"
                                            : "edit",
                                          compact: true,
                                          color: "secondary"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            _vm.isEditingQuestion = !_vm.isEditingQuestion
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isEditingQuestion
                                                ? "Bewerken annuleren"
                                                : "Vraag bewerken"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-w-full tw-mt-8 tw-text-xl tw-p-16"
                              },
                              [
                                !_vm.isEditingQuestion
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.questionData.question) +
                                          " "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.isEditingQuestion
                                  ? [
                                      _c("v-textarea", {
                                        staticClass: "tw-w-full",
                                        attrs: {
                                          placeholder: "Schrijf hier uw bericht"
                                        },
                                        model: {
                                          value: _vm.questionData.question,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.questionData,
                                              "question",
                                              $$v
                                            )
                                          },
                                          expression: "questionData.question"
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        ),
                        _vm.questionData.status === "unanswered" &&
                        !_vm.$store.state.isServiceOrganization
                          ? [
                              _c("div", { staticClass: "tw-w-full tw-pt-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-w-full tw-flex tw-border-gray-200 tw-border tw-border-solid tw-mb-32 tw-flex-wrap"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-w-full tw-p-16 tw-bg-gray-200"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tw-font-bold" },
                                          [_vm._v("Geef antwoord op")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tw-p-16 tw-w-full" },
                                      [
                                        _c("v-textarea", {
                                          staticClass: "tw-w-full",
                                          attrs: {
                                            placeholder:
                                              "Schrijf hier uw bericht"
                                          },
                                          model: {
                                            value: _vm.questionData.answer,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.questionData,
                                                "answer",
                                                $$v
                                              )
                                            },
                                            expression: "questionData.answer"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ]
                          : _vm._e(),
                        _vm.questionData.status === "answered" ||
                        _vm.questionData.status === "accepted" ||
                        _vm.questionData.status === "rejected"
                          ? [
                              _c("div", { staticClass: "tw-w-full tw-mt-24" }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "tw-font-normal tw-text-gray-500 tw-mb-8"
                                  },
                                  [_vm._v("Antwoord")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-w-full tw-flex tw-border tw-border-solid tw-mb-32 tw-flex-wrap",
                                    class:
                                      _vm.statusBorders[_vm.questionData.status]
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-w-full tw-flex tw-justify-between tw-p-16 tw-bg-gray-200"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tw-self-center" },
                                          [
                                            _vm.questionData.status ===
                                            "accepted"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "tw-text-2xl tw-mr-8",
                                                    attrs: { color: "success" }
                                                  },
                                                  [_vm._v(" check ")]
                                                )
                                              : _vm._e(),
                                            _vm.questionData.status ===
                                            "rejected"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "tw-text-2xl tw-mr-8",
                                                    attrs: { color: "error" }
                                                  },
                                                  [_vm._v(" close ")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tw-font-bold tw-text-xl"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.questionData
                                                        .answered_by
                                                        ? _vm.questionData
                                                            .answered_by.name
                                                        : ""
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "tw-text-lg tw-ml-4"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.questionData.answered_at
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.canEditAnswer
                                          ? _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  icon: _vm.isEditingAnswer
                                                    ? "close"
                                                    : "edit",
                                                  compact: true,
                                                  color: "secondary"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    _vm.isEditingAnswer = !_vm.isEditingAnswer
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.isEditingAnswer
                                                        ? "Bewerken annuleren"
                                                        : "Antwoord bewerken"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tw-p-16 tw-w-full" },
                                      [
                                        !_vm.isEditingAnswer
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.questionData.answer
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.isEditingAnswer
                                          ? [
                                              _c("v-textarea", {
                                                staticClass: "tw-w-full",
                                                attrs: {
                                                  placeholder:
                                                    "Schrijf hier uw bericht"
                                                },
                                                model: {
                                                  value:
                                                    _vm.questionData.answer,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.questionData,
                                                      "answer",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "questionData.answer"
                                                }
                                              })
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          !_vm.isLoading
            ? _c("template", { slot: "actions" }, [
                _c(
                  "div",
                  { staticClass: "tw-flex tw-justify-end" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "tw-self-center tw-mr-16 tw-p-8 fadedText link",
                        on: { click: _vm.close }
                      },
                      [_vm._v("Sluiten")]
                    ),
                    !_vm.$store.state.isServiceOrganization &&
                    (_vm.questionData.status === "unanswered" ||
                      _vm.isEditingAnswer)
                      ? _c(
                          "MiButton",
                          {
                            staticClass: "mx-1",
                            attrs: {
                              disabled: !_vm.questionData.answer,
                              color: "primary",
                              icon: "send"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submitAnswer()
                              }
                            }
                          },
                          [_vm._v(" Antwoord verzenden ")]
                        )
                      : _vm._e(),
                    _vm.canEditQuestion && _vm.isEditingQuestion
                      ? _c(
                          "MiButton",
                          {
                            staticClass: "mx-1",
                            attrs: {
                              disabled: !_vm.questionData.question,
                              color: "primary",
                              icon: "send"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.updateQuestion()
                              }
                            }
                          },
                          [_vm._v(" Opslaan ")]
                        )
                      : _vm._e(),
                    _vm.questionData.status === "answered" &&
                    _vm.$store.state.isServiceOrganization
                      ? _c(
                          "MiButton",
                          {
                            attrs: {
                              color: "warning",
                              icon: "remove_circle",
                              outline: true
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.rejectAnswer(_vm.questionData)
                              }
                            }
                          },
                          [_vm._v(" Afkeuren ")]
                        )
                      : _vm._e(),
                    _vm.questionData.status === "answered" &&
                    _vm.$store.state.isServiceOrganization
                      ? _c(
                          "MiButton",
                          {
                            staticClass: "tw-ml-16",
                            attrs: {
                              color: "success",
                              icon: "check_circle",
                              outline: true
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.acceptAnswer(_vm.questionData)
                              }
                            }
                          },
                          [_vm._v(" Goedkeuren ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }