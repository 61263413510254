var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        class: _vm.cssClass,
        attrs: {
          items: _vm.reports,
          "item-text": "case_number",
          "item-value": "id",
          "hide-details": "",
          placeholder: "Rapporten",
          "search-input": _vm.reportSearchInput,
          clearable: "",
          multiple: _vm.multiple
        },
        on: {
          "update:searchInput": function($event) {
            _vm.reportSearchInput = $event
          },
          "update:search-input": function($event) {
            _vm.reportSearchInput = $event
          },
          keyup: this.searchDebounce,
          change: _vm.handleChange
        },
        scopedSlots: _vm._u(
          [
            _vm.multiple
              ? {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      index === 0
                        ? [
                            _c("span", [_vm._v("Rapporten ")]),
                            _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" + _vm._s(_vm.selectedReports.length) + ")"
                              )
                            ])
                          ]
                        : _vm._e()
                    ]
                  }
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedReports,
          callback: function($$v) {
            _vm.selectedReports = $$v
          },
          expression: "selectedReports"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }