import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { CostSpecification } from '@/models/CostSpecification';
import { CostSpecificationQuestion, CreateCostSpecificationQuestionPayload, statusBackgrounds, statusLabels } from '@/models/CostSpecificationQuestion';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import SpecificationQuestionDialog from '@/components/SpecificationQuestionDialog/SpecificationQuestionDialog.vue';
import { User } from '@/models/User';

@Component<SpecificationQuestions>({
  components: {
    SpecificationQuestionDialog,
  },
})
export default class SpecificationQuestions extends Vue {
  // #region @Props

  @Prop()
  protected specification!: CostSpecification;

  // #endregion

  // #region @Refs

  @Ref()
  protected datatable!: DataTable;

  // #endregion

  // #region Class properties

  protected selectedQuestion: CostSpecificationQuestion | null = null;

  protected questionPayload: CreateCostSpecificationQuestionPayload = {
    cost_specification_id: this.$route.params.id,
    question: '',
  }

  protected isCreatingQuestion = false;

  protected showQuestionField = false;

  protected isViewingQuestion = false;

  protected tableFilters = {
    cost_specification: this.$route.params.id,
  };

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  // #endregion

  // #region Async methods

  protected async submitNewQuestion(): Promise<void> {
    this.isCreatingQuestion = true;
    try {
      await new CostSpecificationQuestion()
        .create(this.questionPayload);

      this.showQuestionField = false;
      this.questionPayload.question = '';
      this.datatable.refresh();
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isCreatingQuestion = false;
    }
  }

  // #endregion

  // #region Getters & Setters

  protected get tableOptions(): Object {
    return {
      model: new CostSpecificationQuestion().filter(this.datatableFilters).sort('created_at', 'DESC'),
      headers: [
        {
          text: 'Toegevoegd',
          value: 'created_at',
          sortable: {
            key: 'created_at',
            order: 'ASC',
          },
        },
        {
          text: 'Toegevoegd door',
          value: 'asked_by',
          transform: (user: User) => (user ? user.name : ''),
        },
        {
          text: 'Beantwoord op',
          value: 'answered_at',
          sortable: {
            key: 'key',
            order: 'ASC',
          },
        },
        {
          text: 'Beantwoord door',
          value: 'answered_by',
          transform: (user: User) => (user ? user.name : ''),
        },
        {
          text: 'Beoordeeld op',
          value: 'answered_at',
          sortable: {
            key: 'key',
            order: 'ASC',
          },
        },
        {
          text: 'Beoordeeld door',
          value: 'answered_by',
          transform: (user: User) => (user ? user.name : ''),
          sortable: {
            key: 'key',
            order: 'ASC',
          },
        },
        {
          text: 'Status',
          value: 'status',
          transform: (status: any) => `<span class="tw-text-white tw-py-8 tw-px-16 tw-text-lg tw-rounded-full ${statusBackgrounds[status]}">${statusLabels[status]}</span>`,
          sortable: {
            key: 'key',
            order: 'ASC',
          },
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'remove_red_eye',
          action: async (dataTable: DataTable, question: CostSpecificationQuestion) => {
            this.selectedQuestion = question;
            this.isViewingQuestion = true;
          },
          tooltip: 'Aanpassen',
        },

      ],
      filter: [],
    };
  }

  protected get datatableFilters(): any {
    return this.tableFilters;
  }

  // #endregion
}
