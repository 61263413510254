import { Component, Prop, Vue } from 'vue-property-decorator';
import { CostSpecificationQuestion, statusBackgrounds, statusBorders } from '@/models/CostSpecificationQuestion';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';
import { cloneDeep } from 'lodash';

@Component<SpecificationQuestionDialog>({})
export default class SpecificationQuestionDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

   @Prop()
   protected question!: CostSpecificationQuestion;

   protected questionData: CostSpecificationQuestion | null = null;

   // #endregion

   // #region @Refs
   /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
   // #endregion

   // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected isLoading = true;

  protected isAcceptingAnswer = false;

  protected isEditingAnswer = false;

  protected isEditingQuestion = false;

  protected isRejectingAnswer = false;

  // #endregion

  // #region Lifecycle Hooks / Init

  protected async mounted() {
    this.questionData = cloneDeep(this.question);
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async submitAnswer(question: CostSpecificationQuestion): Promise<void> {
    if (this.$store.state.isServiceOrganization) {
      return;
    }

    const payload = {
      signature: 'cost-speficiation:questions:answer',
      body: {
        question: this.questionData?.id,
        answer: this.questionData?.answer,
        answered_by: this.$store.state.Auth.id,
      },
    };

    this.isLoading = true;
    try {
      const question: CostSpecificationQuestion = await new Rpc()
        .rpcPost(payload);
      this.questionData = question;
      this.$emit('questionUpdated');
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isLoading = false;
      this.isEditingAnswer = false;
    }
  }

  protected async acceptAnswer(question: CostSpecificationQuestion): Promise<void> {
    if (! this.$store.state.isServiceOrganization) {
      return;
    }

    const payload = {
      signature: 'cost-speficiation:questions:accept',
      body: {
        question: this.questionData?.id,
      },
    };

    this.isAcceptingAnswer = true;
    try {
      const question: CostSpecificationQuestion = await new Rpc()
        .rpcPost(payload);
      this.questionData = question;
      this.$emit('questionUpdated');
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isAcceptingAnswer = false;
    }
  }

  protected async rejectAnswer(question: CostSpecificationQuestion): Promise<void> {
    if (! this.$store.state.isServiceOrganization) {
      return;
    }

    const payload = {
      signature: 'cost-speficiation:questions:reject',
      body: {
        question: this.questionData?.id,
      },
    };

    this.isRejectingAnswer = true;
    try {
      const question: CostSpecificationQuestion = await new Rpc()
        .rpcPost(payload);
      this.questionData = question;
      this.$emit('questionUpdated');
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isRejectingAnswer = false;
    }
  }

  protected async updateQuestion(): Promise<void> {
    if (! this.questionData) {
      return;
    }
    this.isLoading = true;
    try {
      const question: CostSpecificationQuestion = await this.question?.update(this.questionData);
      this.isEditingQuestion = false;
      this.questionData = question;
      this.$emit('questionUpdated');
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isLoading = false;
    }
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get canEditAnswer(): boolean {
    return ! this.$store.state.isServiceOrganization && !! this.questionData?.answer
    && (this.questionData?.status !== 'accepted' && this.questionData?.status !== 'rejected');
  }

  protected get canEditQuestion(): boolean {
    return this.$store.state.isServiceOrganization && this.questionData?.status === 'unanswered';
  }

  protected get statusBorders() {
    return statusBorders;
  }

  protected get statusBackgrounds() {
    return statusBackgrounds;
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
